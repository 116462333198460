/* eslint-disable no-unused-expressions */
import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { groupUserUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import DeleteDialog from '@/views/master/user-admin/group-user/components/DeletePermissionDialog.vue'
import AddPermissionDialog from '@/views/master/user-admin/group-user/components/AddPermissionDialog.vue'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import DialogConfirm from '@/views/shared-components/dialog/Confirm.vue'
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxScrolling,
  DxColumnChooser,
  DxHeaderFilter,
  DxFilterRow,
  DxExport,
  DxPager
} from 'devextreme-vue/data-grid'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import { downloadExcel } from '@/plugins/report/downloadExcel'

export default {
  components: {
    DeleteDialog,
    AddPermissionDialog,
    DialogConfirm,
    DxColumn,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxDataGrid,
    DxScrolling,
    DxColumnChooser,
    DxHeaderFilter,
    DxFilterRow,
    DxExport,
    DxLoadPanel,
    DxPager
  },
  setup() {
    const route = useRoute()
    const app = getCurrentInstance()
    const store = useStore()
    const router = useRouter()
    const { $toast, $strInd } = app!.appContext.config.globalProperties
    const currentId = (): string => encryptDecriptMethods.decrypt(route.params.id)
    const groupUserName = ref()
    const selectedPermission = ref()
    const userPermissionList = ref()
    const op = ref()
    const isShowDeleteDialog = ref(false)
    const isShowAddPermissionDialog = ref(false)
    const inputSearch = ref('')
    const refDialogConfirm = ref<InstanceType<typeof DialogConfirm>>()

    const noDataText = ref('Tidak Ada Data')
    const pageSizes = ref([5, 10, 15, 20, 25, 50, 100]) as any
    const displayMode = ref('full')
    const showPageSizeSelector = ref(true)
    const showInfo = ref(true)
    const showNavButtons = ref(true)
    const showFilterRow = ref(true)
    const showHeaderFilter = ref(true)
    const autoExpandAll = ref(true)

    const redirectToList = () => {
      router.push({ name: 'master-grup-user' })
    }

    const dataEditNotFound = () => {
      // redirectToList()
      $toast.add({
        severity: 'error', detail: 'data tidak ditemukan', group: 'bc', life: 3000
      })
    }

    const getGroupUserDetail = async () => {
      store.dispatch('showLoading')
      const response = await groupUserUseCase.getDataForm(currentId())
      if (!response.error) {
        if (!response.result) {
          dataEditNotFound()
        }
        groupUserName.value = response.result.Nama
      } else if (response.code === 404) {
        dataEditNotFound()
      }
      store.dispatch('hideLoading')
    }

    const getPermissionList = async () => {
      store.dispatch('showLoading')
      const response = await groupUserUseCase.getUserPermissions(currentId())
      if (!response.error) {
        if (!response.result) {
          dataEditNotFound()
        }
        userPermissionList.value = response.result.map((data: any) => {
          const textArr = data.Description.split(' - ')
          const obj = {
            ...data,
            groupName: textArr[0]
          }
          return obj
        })
        // userPermissionList.value = response.result
        console.log('list', userPermissionList.value)
      } else if (response.code === 404) {
        dataEditNotFound()
      }
      store.dispatch('hideLoading')
    }

    const showDropDown = (evt: any, data: any) => {
      console.log('data', data)
      selectedPermission.value = data
      op.value.toggle(evt)
    }

    const onSubmitDelete = async () => {
      store.dispatch('showLoading')
      const response = await groupUserUseCase.deleteSingleUserPermission(Number(currentId()), selectedPermission.value?.Id)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: response.message, group: 'bc', life: 3000
        })
        getGroupUserDetail()
        getPermissionList()
      }
      selectedPermission.value = null
      store.dispatch('hideLoading')
    }

    const deleteData = () => {
      refDialogConfirm.value?.openModal({
        imgContent: require('@/assets/img/permission-delete.png'),
        title: '',
        subTitle: `Yakin Akan Hapus Permission <span class="font-semibold">${selectedPermission.value.Name}</span> pada Grup User <span class="font-semibold">${groupUserName.value}</span> ?`,
        btnSubmitTitle: 'Yakin',
        btnBackTitle: 'Batalkan'
      }).then((isConfirm) => {
        if (isConfirm) {
          onSubmitDelete()
        }
      })
    }

    const addPermission = () => {
      isShowAddPermissionDialog.value = true
    }

    const hideAddPermissionDialog = () => {
      isShowAddPermissionDialog.value = false
    }

    const onSubmitPermission = async (id: any, data: any) => {
      store.dispatch('showLoading')
      const dataForm = {
        PermissionIds: data
      }
      const response = await groupUserUseCase.addGroupUserPermission(id, dataForm)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        getGroupUserDetail()
        getPermissionList()
      }
      store.dispatch('hideLoading')
    }

    const exportGrids = (e: any) => {
      try {
        downloadExcel.exportGridExcel(userPermissionList.value, 'Group User Permission')
      } catch {
        $toast.add({
          severity: 'error', detail: $strInd.errorDownload, group: 'bc', life: 1500
        })
      }
    }

    onMounted(() => {
      getGroupUserDetail()
      getPermissionList()
    })

    return {
      currentId,
      groupUserName,
      userPermissionList,
      deleteData,
      showDropDown,
      op,
      isShowDeleteDialog,
      onSubmitDelete,
      isShowAddPermissionDialog,
      hideAddPermissionDialog,
      onSubmitPermission,
      addPermission,
      inputSearch,
      refDialogConfirm,
      noDataText,
      pageSizes,
      displayMode,
      showPageSizeSelector,
      showInfo,
      showNavButtons,
      showFilterRow,
      showHeaderFilter,
      autoExpandAll,
      exportGrids
    }
  }
}
